import useContractList from "../../lib/api/useContractList";
import {
  PAGE_START,
  PAGE_SIZE,
  DEFAULT_STATUS_LIST,
} from "../../config/setting";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { formatTimestamp } from "../../lib/tools";
import { TextButton } from "../../components/Button";
import {
  CONTRACT_PLAN_OPTIONS,
  CONTRACT_STATUS_OPTIONS,
  PAYMENT_STATUS_OPTIONS,
} from "../../config/enum";
import { FilterBarStyled } from "../../components/FilterBar";
import {
  TextField,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import {
  ZoomiDataGrid,
  ZoomiPagination,
  CustomToolbar,
} from "../../components/DataGrid";
import { ContractStatus } from "../../config/setting";

// 合約管理
const ContractManagement = () => {
  const [contractList, isLoading, fetchContractList] = useContractList();
  const [filterParams, setFilterParams] = useState({
    createMillisDesc: true,
    statusList: DEFAULT_STATUS_LIST,
    page: PAGE_START,
    size: PAGE_SIZE,
  });
  const navigate = useNavigate();

  useEffect(() => {
    fetchContractList(filterParams);
  }, [filterParams.page, filterParams.pageSize]);

  const handleSelectedContract = (contract) => {
    navigate("/contract-management/" + contract.recordId, {
      state: { contract },
    });
  };

  const handleFilterParamsChange = (key, value) => {
    if (value === "ALL" || value[0] === "ALL") {
      value = null;
      if (key === "statusList") {
        value = DEFAULT_STATUS_LIST;
      }
    }

    setFilterParams({ ...filterParams, [key]: value });
  };

  const handleUpdatecontractList = () => {
    setFilterParams((prevParams) => ({
    ...prevParams,
    page: PAGE_START,
    }));
    fetchContractList(filterParams);
  };

  const handleSelectedPage = (event, value) => {
    setFilterParams({ ...filterParams, page: value - 1 });
  };

  const columns = [
    { field: "recordId", headerName: "合約編號", minWidth: 180, flex: 1 },
    { field: "email", headerName: "帳號", minWidth: 180, flex: 1 },
    { field: "userName", headerName: "姓名", minWidth: 80, flex: 1 },
    { field: "contractName", headerName: "方案", minWidth: 100, flex: 1 },
    {
      field: "phoneType",
      headerName: "手機型號",
      minWidth: 200,
      flex: 1.5,
      renderCell: (params) => (
        <div>{`${params.row.phoneType.phoneName}(${params.row.phoneType.phoneColor}/${params.row.phoneType.phoneCapacity})`}</div>
      ),
    },
    { field: "phoneImei", headerName: "IMEI", minWidth: 120, flex: 1 },
    {
      field: "contractStartMillis",
      headerName: "合約期間",
      flex: 1.5,
      minWidth: 170,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <p>
            {params.row.contractStartMillis
              ? formatTimestamp(params.row.contractStartMillis)
              : ""}
          </p>

          <p>
            {params.row.contractEndMillis
              ? "~" + formatTimestamp(params.row.contractEndMillis)
              : ""}
          </p>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "合約狀態",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => <div>{ContractStatus(params.row).label}</div>,
    },
    {
      field: "createMillis",
      headerName: "建立時間",
      flex: 1.5,
      minWidth: 170,
      renderCell: (params) => (
        <div>
          {params.row.createMillis
            ? new Date(params.row.createMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "操作",
      flex: 1,
      minWidth: 80,
      renderCell: (params) => (
        <>
          {
            <TextButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleSelectedContract(params.row)}
            >
              {params.row.status === "IN_REVIEW" ? "審核" : "查看"}
            </TextButton>
          }
        </>
      ),
    },
  ];

  return (
    <div>
      <Typography color="primary" variant="h3" align="left">
        合約管理
      </Typography>
      <FilterBarStyled>
        <TextField
          color="primary"
          id="outlined-basic"
          label="帳號搜尋"
          variant="outlined"
          size="small"
          sx={{ minWidth: "8rem" }}
          onChange={(e) => handleFilterParamsChange("email", e.target.value)}
        />
        <TextField
          color="primary"
          id="outlined-basic"
          label="姓名搜尋"
          variant="outlined"
          size="small"
          sx={{ minWidth: "8rem" }}
          onChange={(e) => handleFilterParamsChange("name", e.target.value)}
        />
        <TextField
          color="primary"
          id="outlined-basic"
          label="IMEI搜尋"
          variant="outlined"
          size="small"
          sx={{ minWidth: "8rem" }}
          onChange={(e) =>
            handleFilterParamsChange("phoneImei", e.target.value)
          }
        />
        <TextField
          id="outlined-select-currency"
          select
          label="方案"
          size="small"
          sx={{ minWidth: "8rem" }}
          defaultValue={CONTRACT_PLAN_OPTIONS.get("ALL").value}
          onChange={(e) =>
            handleFilterParamsChange("contractName", e.target.value)
          }
        >
          {Array.from(CONTRACT_PLAN_OPTIONS.keys()).map((key, index) => (
            <MenuItem key={index} value={CONTRACT_PLAN_OPTIONS.get(key).value}>
              {CONTRACT_PLAN_OPTIONS.get(key).label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="outlined-select-currency"
          select
          label="合約"
          size="small"
          sx={{ minWidth: "8rem" }}
          defaultValue={CONTRACT_STATUS_OPTIONS.get("ALL").value}
          onChange={(e) =>
            handleFilterParamsChange("statusList", e.target.value)
          }
        >
          {Array.from(CONTRACT_STATUS_OPTIONS.keys()).map((key, index) => (
            <MenuItem
              key={index}
              value={CONTRACT_STATUS_OPTIONS.get(key).value}
            >
              {CONTRACT_STATUS_OPTIONS.get(key).label}
            </MenuItem>
          ))}
        </TextField>
        <TextButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleUpdatecontractList()}
        >
          查詢
        </TextButton>
      </FilterBarStyled>

      {contractList ? (
        <ZoomiDataGrid
          getRowId={(row) => row.recordId}
          rows={contractList ? contractList.userContracts : []}
          columns={columns}
          loading={isLoading}
          disableRowSelectionOnClick
          disableColumnFilter
          autoHeight
          sx={{ "--DataGrid-overlayHeight": "300px" }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 400px)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ZoomiPagination
        page={filterParams.page + 1}
        onChange={handleSelectedPage}
        count={contractList?.totalPage}
      />
    </div>
  );
};

export default ContractManagement;
