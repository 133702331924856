import { useEffect, useState } from "react";
import useNewsList from "../../lib/api/useNewsList";
import {
  TextField,
  MenuItem,
  Typography,
  Box,
  CircularProgress,
  Switch,
} from "@mui/material";
import { TextButton } from "../../components/Button";
import { ZoomiDataGrid, ZoomiPagination } from "../../components/DataGrid";
import { PAGE_START, PAGE_SIZE } from "../../config/setting";
import { NEWS_STATUS } from "../../config/enum";
import { FilterBarStyled } from "../../components/FilterBar";
import { useNavigate } from "react-router-dom";
import useNewsModify from "../../lib/api/useNewsModify";
import {
  ZoomiDialogStyled,
  DialogTitleStyled,
  DialogContentStyled,
  DialogContentActionStyled,
} from "../../components/Dialog";

const NewsList = () => {
  const [newsList, newsListLoading, fetchNewsList] = useNewsList();
  const [newsListParams, setNewsListParams] = useState({
    createMillisDesc: true,
    statusList: ["ENABLE", "DISABLE"],
    page: PAGE_START,
    size: PAGE_SIZE,
  });
  const [newsModify, newsModifyLoading, requestNewsModify] = useNewsModify();
  const navigate = useNavigate();

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedNews, setSelectedNews] = useState(null);

  useEffect(() => {
    fetchNewsList(newsListParams);
  }, [newsListParams.page, newsListParams.pageSize]);

  useEffect(() => {
    if (newsModify) {
      fetchNewsList(newsListParams);
    }
  }, [newsModify]);

  const handleSelectedPage = (event, value) => {
    setNewsListParams({ ...newsListParams, page: value - 1 });
  };

  const handleEditNews = (actionType, news) => {
    navigate("/news-list/" + actionType + (news ? "/" + news.id : ""));
  };

  const handleDeleteNews = (news, onClose) => {
    requestNewsModify({
      id: news.id,
      title: news.title,
      content: news.content,
      status: "DELETE",
    });
    onClose();
  };

  const handleSwitchNewsStatus = (checked, news) => {
    let newNews = {
      id: news.id,
      title: news.title,
      content: news.content,
    };

    if (checked) {
      requestNewsModify({
        ...newNews,
        status: "ENABLE",
      });
    } else {
      requestNewsModify({
        ...newNews,
        status: "DISABLE",
      });
    }
  };

  const handleUpdateNewsList = () => {
    setNewsListParams((prevParams) => ({
      ...prevParams,
      page: PAGE_START,
    }));
    fetchNewsList(newsListParams)
  }

  const handleParamsChange = (key, value) => {
    setNewsListParams({ ...newsListParams, [key]: value });
  };

  const dataGridColumns = [
    {
      field: "picBase64",
      headerName: "Banner",
      flex: 1.5,
      minWidth: 150,
      renderCell: (params) => (
        <img
          alt="banner"
          src={`data:image/jpeg;base64,${params.row?.picBase64}`}
          style={{ width: "120px", height: "90%" }}
        />
      ),
    },
    { field: "title", headerName: "標題", minWidth: 150, flex: 1 },
    {
      field: "createMillis",
      headerName: "建立時間",
      flex: 1.5,
      minWidth: 180,
      renderCell: (params) => (
        <div>
          {params.row.createMillis
            ? new Date(params.row.createMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "updateMillis",
      headerName: "更新時間",
      flex: 1.5,
      minWidth: 180,
      renderCell: (params) => (
        <div>
          {params.row.updateMillis
            ? new Date(params.row.updateMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "狀態",
      flex: 1.5,
      minWidth: 80,
      renderCell: (params) => (
        <>
          <Switch
            disabled={newsModifyLoading}
            defaultChecked={params.row.status === "ENABLE"}
            onChange={(e) =>
              handleSwitchNewsStatus(e.target.checked, params.row)
            }
          />
        </>
      ),
    },
    {
      field: "action",
      headerName: "操作",
      flex: 2,
      minWidth: 150,
      renderCell: (params) => (
        <>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{
              marginRight: "10px",
            }}
            onClick={() => handleEditNews("edit", params.row)}
          >
            編輯
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            loading={!!newsModifyLoading}
            onClick={() => {
              setSelectedNews(params.row);
              setShowDeleteDialog(true);
            }}
          >
            刪除
          </TextButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <Typography color="primary" variant="h3" align="left">
        最新消息
      </Typography>
      <FilterBarStyled>
        <TextField
          color="primary"
          id="outlined-basic"
          label="標題搜尋"
          sx={{ minWidth: "10rem" }}
          variant="outlined"
          size="small"
          onChange={(e) => handleParamsChange("title", e.target.value)}
        />
        <TextField
          id="outlined-select-currency"
          color="primary"
          select
          label="狀態"
          sx={{ minWidth: "10rem" }}
          size="small"
          defaultValue={NEWS_STATUS.get("ALL").value}
          onChange={(e) => handleParamsChange("statusList", e.target.value)}
        >
          {Array.from(NEWS_STATUS.keys()).map((key) => (
            <MenuItem key={key} value={NEWS_STATUS.get(key).value}>
              {NEWS_STATUS.get(key).label}
            </MenuItem>
          ))}
        </TextField>
        <TextButton
          variant="contained"
          color="primary"
          size="small"
          onClick={()=> handleUpdateNewsList()}
        >
          查詢
        </TextButton>
        <TextButton
          variant="contained"
          color="primary"
          size="small"
          sx={{ marginLeft: "auto" }}
          onClick={() => handleEditNews("create")}
        >
          新增
        </TextButton>
      </FilterBarStyled>

      {newsList ? (
        <>
          <ZoomiDataGrid
            getRowId={(row) => row.id}
            columns={dataGridColumns}
            loading={!!newsListLoading}
            disableRowSelectionOnClick
            disableColumnFilter
            autoHeight
            rowHeight={80}
            sx={{ "--DataGrid-overlayHeight": "300px" }}
            rows={newsList ? newsList.newsDtos : []}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 400px)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ZoomiPagination
        page={newsListParams.page + 1}
        onChange={handleSelectedPage}
        count={newsList?.totalPage}
      />
      <ZoomiDialogStyled open={showDeleteDialog}>
        <DialogTitleStyled color="primary" variant="h4">
          刪除最新消息
        </DialogTitleStyled>
        <DialogContentStyled>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            資料刪除後不可復原，請問是否刪除此最新消息？
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            標題：{selectedNews?.title}
          </Typography>
        </DialogContentStyled>
        <DialogContentActionStyled>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setShowDeleteDialog(false)}
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              handleDeleteNews(selectedNews, () => setShowDeleteDialog(false))
            }
          >
            確認
          </TextButton>
        </DialogContentActionStyled>
      </ZoomiDialogStyled>
    </div>
  );
};

export default NewsList;
