import { useEffect, useState } from "react";
import useAdminList from "../../lib/api/useAdminList";
import {
  Typography,
  CircularProgress,
  TextField,
  MenuItem,
  Box,
  DialogContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { MemberManagementStyled } from "./styled";
import {
  ZoomiDataGrid,
  CustomToolbar,
  ZoomiPagination,
} from "../../components/DataGrid";
import { FilterBarStyled } from "../../components/FilterBar";
import { TextButton } from "../../components/Button";
import {
  ADMIN_STATUS_SELECT,
  ADMIN_STATUS,
  ADMIN_ROLE,
} from "../../config/enum";
import useJobList from "../../lib/api/useJobList";
import { PAGE_START, PAGE_SIZE } from "../../config/setting";
import {
  DialogContentActionStyled,
  ZoomiDialogStyled,
} from "../../components/Dialog";
import useAdminModify from "../../lib/api/useAdminModify";

const UserManagement = () => {
  const [adminModify, adminModifyLoading, requestAdminModify] =
    useAdminModify();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [adminList, adminListLoading, fetchAdminList] = useAdminList({
    page: PAGE_START,
    size: PAGE_SIZE,
  });
  const [filterParams, setFilterParams] = useState({
    page: PAGE_START,
    size: PAGE_SIZE,
    statusList: ["NORMAL", "FROZEN"],
  });
  const [jobList, jobListLoading, fetchJobList] = useJobList();

  const navigate = useNavigate();

  useEffect(() => {
    fetchJobList();
  }, []);

  useEffect(() => {
    fetchAdminList(filterParams);
  }, [filterParams.page, filterParams.pageSize]);

  useEffect(() => {
    if (adminModify && adminModify.code === 0) {
      fetchAdminList(filterParams);
    }
  } , [adminModify]);


  const handleAdminStoreDelete = (store) => {
    requestAdminModify(
      {
        adminId: store?.id,
        name: store?.name,
        role: store?.role,
        storefrontId: store?.storefrontId,
        status: 'DELETE'
      });
    setShowConfirmDialog(false);
  };
  const handleSelectedMemberDetail = (member) => {
    navigate("/member-management/" + member.id, {
      state: { member },
    });
  };

  const handleUserManagement = (user) => {
    navigate("/user-management/modify", {
      state: { user },
    });
  };

  const handleDeleteStore = (store, onOpen) => {
    setSelectedStore(store);
    onOpen();
  };

  const handleFilterParamsChange = (key, value) => {
    if (value === "ALL" || value[0] === "ALL") {
      value = null;
    }
    setFilterParams({ ...filterParams, [key]: value });
  };

  const handleUpdateMemberList = () => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      page: PAGE_START,
    }));
    fetchAdminList(filterParams);
  };

  const handleSelectedPage = (event, value) => {
    setFilterParams({ ...filterParams, page: value - 1 });
  };

  const columns = [
    { field: "name", headerName: "使用者名稱", flex: 0.5 },
    {
      field: "email",
      headerName: "帳號",
      flex: 1,
      type: "string",
    },
    {
      field: "storefrontName",
      headerName: "門市",
      flex: 0.5,
    },
    {
      field: "role",
      headerName: "角色",
      flex: 0.5,
      renderCell: (params) => <>{ADMIN_ROLE.get(params.row.role)?.label}</>,
    },
    {
      field: "status",
      headerName: "狀態",
      flex: 0.5,
      renderCell: (params) => <>{ADMIN_STATUS.get(params.row.status)?.label}</>,
    },
    {
      field: "createMillis",
      headerName: "建立時間",
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row.createMillis
            ? new Date(params.row.createMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "updateMillis",
      headerName: "更新時間",
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row.updateMillis
            ? new Date(params.row.updateMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "action",
      headerName: "操作",
      flex: 1.5,
      renderCell: (params) => (
        <>
          <TextButton
            variant="contained"
            size="small"
            color="primary"
            sx={{
              marginRight: "10px",
            }}
            onClick={() => handleUserManagement(params.row)}
          >
            編輯
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              handleDeleteStore(params.row, () => setShowConfirmDialog(true))
            }
          >
            刪除
          </TextButton>
        </>
      ),
    },
  ];

  return (
    <div>
      <MemberManagementStyled>
        <Typography color="primary" variant="h3" align="left">
          使用者管理
        </Typography>
        <FilterBarStyled>
          <TextField
            color="primary"
            id="outlined-basic"
            label="使用者名稱搜尋"
            variant="outlined"
            size="small"
            onChange={(e) => handleFilterParamsChange("name", e.target.value)}
          />
          <TextField
            color="primary"
            id="outlined-basic"
            label="帳號搜尋"
            variant="outlined"
            size="small"
            onChange={(e) => handleFilterParamsChange("email", e.target.value)}
          />
          <TextField
            id="outlined-select-currency"
            select
            size="small"
            label="狀態"
            sx={{ minWidth: "10rem" }}
            defaultValue={ADMIN_STATUS_SELECT.get("ALL").value}
            onChange={(e) =>
              handleFilterParamsChange("statusList", [e.target.value])
            }
          >
            {Array.from(ADMIN_STATUS_SELECT.keys()).map((key) => (
              <MenuItem key={key} value={ADMIN_STATUS_SELECT.get(key).value}>
                {ADMIN_STATUS_SELECT.get(key).label}
              </MenuItem>
            ))}
          </TextField>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleUpdateMemberList()}
          >
            查詢
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            sx={{ marginLeft: "auto" }}
            onClick={() => handleUserManagement()}
          >
            新增
          </TextButton>
        </FilterBarStyled>

        {adminList ? (
          <ZoomiDataGrid
            id
            rows={
              adminList
                ? adminList.admins.filter((el) => el.status !== "DELETE")
                : []
            }
            columns={columns}
            loading={adminListLoading}
            disableRowSelectionOnClick
            disableColumnFilter
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 400px)",
            }}
          >
            <CircularProgress />
          </Box>
        )}
        <ZoomiPagination
          page={filterParams.page + 1}
          onChange={handleSelectedPage}
          count={adminList?.totalPage}
        />
      </MemberManagementStyled>
      <ZoomiDialogStyled open={showConfirmDialog}>
        <Typography color="primary" variant="h4">
          {"資料刪除後不可復原，請問是否刪除此使用者？"}
        </Typography>
        <DialogContent>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            使用者名稱: {selectedStore?.name}
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            帳號: {selectedStore?.email}
          </Typography>
          <Typography color="primary" variant="h5" marginBottom={"1rem"}>
            門市: {selectedStore?.storefrontName}
          </Typography>
        </DialogContent>
        <DialogContentActionStyled>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setShowConfirmDialog(false)}
          >
            取消
          </TextButton>
          <TextButton
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleAdminStoreDelete(selectedStore)}
          >
            確認
          </TextButton>
        </DialogContentActionStyled>
      </ZoomiDialogStyled>
    </div>
  );
};

export default UserManagement;
