import { CircularProgress, Box } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { MESSAGE_TYPE } from "./../../config/enum";
import { SnackbarProvider, useSnackbar } from "notistack";
import useMemberKYCList from "../../lib/api/useMemberKYCList";
import { Filter } from "@mui/icons-material";
import { FilterBarStyled } from "../../components/FilterBar";
import { TextField, MenuItem, Typography, Pagination } from "@mui/material";
import { TextButton } from "../../components/Button";
import {
  ZoomiDataGrid,
  ZoomiPagination,
  CustomNoRowsOverlay,
} from "../../components/DataGrid";
import { useNavigate } from "react-router-dom";
import { MEMBER_KYC_STATUS } from "./../../config/enum";
import { PAGE_SIZE, PAGE_START } from "../../config/setting";

const MemberVerification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [memberKYCList, isLoading, fetchMemberKYCList] = useMemberKYCList();

  const [filterParams, setFilterParams] = useState({
    createMillisDesc: true,
    page: PAGE_START,
    size: PAGE_SIZE,
  });

  const handleSelectedPage = (event, value) => {
    setFilterParams({ ...filterParams, page: value - 1 });
  };

  useEffect(() => {
    fetchMemberKYCList(filterParams);
  }, [filterParams.page, filterParams.pageSize]);

  const handleFilterParamsChange = (key, value) => {
    if (key === "statusList" && value[0] === "ALL") {
      value = [];
      setFilterParams({ ...filterParams, [key]: value });
    }

    setFilterParams({ ...filterParams, [key]: value });
  };

  const handleUpdateMemberList = () => {
    setFilterParams((prevParams) => ({
      ...prevParams,
      page: PAGE_START,
    }));
    fetchMemberKYCList(filterParams);
  };

  const handleSelectedMemberKYCReview = (member) => {
    navigate("/member-verification/" + member.id, {
      state: { member },
    });
  };

  const columns = [
    { field: "email", headerName: "帳號", minWidth: 200, flex: 2 },
    { field: "name", headerName: "姓名", minWidth: 100, flex: 1 },
    { field: "birthdayStr", headerName: "出生日期", minWidth: 100, flex: 1 },
    { field: "idNumber", headerName: "身份證字號", minWidth: 120, flex: 1 },
    {
      field: "createMillis",
      headerName: "建立時間",
      flex: 2,
      minWidth: 180,
      renderCell: (params) => (
        <div>
          {params.row.createMillis
            ? new Date(params.row.createMillis).toLocaleString()
            : ""}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "狀態",
      flex: 0.8,
      minWidth: 100,
      renderCell: (params) => (
        <div>{MEMBER_KYC_STATUS.get(params.row.status)?.label}</div>
      ),
    },
    {
      field: "action",
      headerName: "操作",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <>
          {
            <TextButton
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleSelectedMemberKYCReview(params.row)}
            >
              {params.row.status === "IN_REVIEW" ? "審核" : "查看"}
            </TextButton>
          }
        </>
      ),
    },
  ];

  return (
    <div>
      <Typography color="primary" variant="h3" align="left">
        實名認證
      </Typography>
      <FilterBarStyled>
        <TextField
          color="primary"
          id="outlined-basic"
          label="帳號搜尋"
          variant="outlined"
          size="small"
          sx={{ minWidth: "8rem" }}
          onChange={(e) => handleFilterParamsChange("email", e.target.value)}
        />
        <TextField
          color="primary"
          id="outlined-basic"
          label="姓名搜尋"
          variant="outlined"
          size="small"
          sx={{ minWidth: "8rem" }}
          onChange={(e) => handleFilterParamsChange("name", e.target.value)}
        />
        <TextField
          color="primary"
          id="outlined-basic"
          label="身分證字號搜尋"
          variant="outlined"
          size="small"
          sx={{ minWidth: "8rem" }}
          onChange={(e) => handleFilterParamsChange("idNumber", e.target.value)}
        />
        <TextField
          id="outlined-select-currency"
          color="primary"
          select
          label="狀態"
          sx={{ minWidth: "8rem" }}
          size="small"
          defaultValue={MEMBER_KYC_STATUS.get("ALL").value}
          onChange={(e) =>
            handleFilterParamsChange("statusList", [e.target.value])
          }
        >
          {Array.from(MEMBER_KYC_STATUS.keys()).map((key) => (
            <MenuItem key={key} value={MEMBER_KYC_STATUS.get(key).value}>
              {MEMBER_KYC_STATUS.get(key).label}
            </MenuItem>
          ))}
        </TextField>
        <TextButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleUpdateMemberList()}
        >
          查詢
        </TextButton>
      </FilterBarStyled>

      {memberKYCList ? (
        <ZoomiDataGrid
          id
          rows={memberKYCList ? memberKYCList.userKycRecords : []}
          columns={columns}
          loading={isLoading}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          disableRowSelectionOnClick
          disableColumnFilter
          autoHeight
          sx={{ "--DataGrid-overlayHeight": "300px" }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 400px)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <ZoomiPagination
        page={filterParams.page + 1}
        onChange={handleSelectedPage}
        count={memberKYCList?.totalPage}
      />
    </div>
  );
};

export default MemberVerification;
