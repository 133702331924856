import { useContext, useMemo } from "react";
import { MESSAGE_TYPE } from "../../config/enum";
import { useSnackbar } from "notistack";
import { AuthContext } from "./../context/authContext";

const useErrorHandler = () => {
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleError = useMemo(
    () => (code) => {
      switch (code) {
        case 3002:
          // token expired
          authContext.tokenExpiredHandler();
          break;

        case 3006:
          enqueueSnackbar("帳號或密碼錯誤", {
            variant: MESSAGE_TYPE.ERROR,
          });
          break;
        case 3013:
          enqueueSnackbar("請先完成身份驗證", { variant: MESSAGE_TYPE.ERROR });
          break;
        case 3017:
          enqueueSnackbar("查無帳號", { variant: MESSAGE_TYPE.ERROR });
          break;
        case 3018:
          enqueueSnackbar("帳號已存在", { variant: MESSAGE_TYPE.ERROR });
          break;
        case 3019:
          enqueueSnackbar("不允許操作", { variant: MESSAGE_TYPE.ERROR });
          break;
        case 3020:
          enqueueSnackbar("手機規格重複", { variant: MESSAGE_TYPE.ERROR });
          break;
        case 3021:
          enqueueSnackbar("合約尚未到期", { variant: MESSAGE_TYPE.ERROR });
          break;
        case 3024:
          enqueueSnackbar("問題重複", { variant: MESSAGE_TYPE.ERROR });
          break;
        case 3025:
          enqueueSnackbar("標題重複", { variant: MESSAGE_TYPE.ERROR });
          break;
        case 3032:
          enqueueSnackbar("門市重複建立", { variant: MESSAGE_TYPE.ERROR });
          break;
        case 3040:
          enqueueSnackbar("IMEI重複", { variant: MESSAGE_TYPE.ERROR });
          break;
        case 3042:
          enqueueSnackbar("此手機號碼已完成實名認證", { variant: MESSAGE_TYPE.ERROR });
          break;
        case 904:
          enqueueSnackbar("無權限", { variant: MESSAGE_TYPE.ERROR });
          break;
        default:
          enqueueSnackbar("發生錯誤", { variant: MESSAGE_TYPE.ERROR });
          break;
      }
    },
    [authContext, enqueueSnackbar]
  );

  return [handleError];
};

export default useErrorHandler;
